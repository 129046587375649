.footerArea{
	background: #006649;
	max-width: 1400px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-bottom: 50px;
}

.ContactArea{
	display: block;
	margin-left: auto;
	margin-right: auto;
	background: #f9c160;
	width: 50%;
	min-width: 500px;
	min-height: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-radius: 20px;
	box-shadow: 1px 5px 5px;
	margin-top: 30px;
}

.ContactAreaSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	background: #f9c160;
	width: 90%;
	min-height: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-radius: 20px;
	box-shadow: 1px 5px 5px;
	margin-top: 30px;
}

.contactInfo{
	display: block;
	margin-left: auto;
	font-family: Anek;
	font-size: 16pt;
	text-align: center;
	color: #006649;
	width: 100%;
}

.SendEmail{
	display: inline-block;
	width: 45%;
	text-align: right;
	margin-left: auto;
	margin-right: 2.5%;
}

.contactInfo a{
	display: inline-block;
	margin-left: 2.5%;
	margin-right: auto;
	width: 45%;
	text-align: left;
}

.contactInfoSmall{
	display: block;
	margin-left: auto;
	font-family: Anek;
	font-size: 12pt;
	text-align: center;
	color: #006649;
	width: 100%;
}

.SendEmailSmall{
	display: inline-block;
	width: 45%;
	text-align: right;
	margin-left: auto;
	margin-right: 2.5%;
}

.contactInfoSmall a{
	display: inline-block;
	margin-left: 2.5%;
	margin-right: auto;
	width: 45%;
	text-align: left;
}

.DownloadAppSection{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	text-align: center;
	font-family: Anek;
	font-size: 16pt;
	color: #fff;
}

.DownloadAppSection label{
	
}

.DownloadAppSectionSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	text-align: center;
	font-family: Anek;
	font-size: 12pt;
	color: #fff;
}

.DownloadAppBtns{
	display: block;
	width: 100%;
	margin-top: 10px;
	padding-bottom: 30px;
}

.DownloadAppBtns a{
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0px;
	padding: 0;
	
}

.DownloadAppBtns img{
	width: 150px;
}

.FinalInfo{
	display: block;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	padding-bottom: 20px;
}

.FinalInfo label{
	font-family: Anek;
	font-size: 14pt;
	font-weight: 500;
	color: #f3e1cb;
}

.FinalInfo img{
	width: 30px;
	vertical-align: middle;
	margin-left: 20px;
}

.CopyrightText{
	float: right;
}

.FinalInfoSmall{
	display: block;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	padding-bottom: 20px;
}

.FinalInfoSmall label{
	font-family: Anek;
	font-size: 12pt;
	font-weight: 500;
	color: #f3e1cb;
}

.FinalInfoSmall img{
	width: 30px;
	vertical-align: middle;
	margin-left: 20px;
}

.CopyrightTextSmall{
	display: block;
	font-size: 10pt !important;
	margin-top: 20px;
}
