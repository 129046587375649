.App {

}

/*-----------*/

@font-face {
  font-family: SF-Pro;
  src: url("./fonts/SF-Pro.ttf");
}

@font-face {
  font-family: Anek;
  src: url("./fonts/AnekMalayalam-VariableFont_wdth,wght.ttf");
}